import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IEmployee } from '../model/Employee.model';
import { CustomPopover } from './custom-popover';
import { EmployeePopover } from './employee-popover';

import './employee-icon.scss';
import { faBolt, faCube, faSyringe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  employee: IEmployee;
  enablePopover?: boolean;
  showLabel?: boolean;
  hideTags?: boolean;
  showSiege?: boolean;
}

export const EmployeeIcon: React.FC<IProps> = ({
  employee,
  showLabel,
  enablePopover,
  hideTags,
  showSiege
}) => {
  const image = employee.smallAvatar ? getImage(employee.smallAvatar) : null;
  return (
    <CustomPopover
      enabled={enablePopover}
      customClass="character-hover-box"
      popover={<EmployeePopover employee={employee} />}
    >
      <Link to={'/counter-side/characters/' + employee.slug}>
        <div className={`avatar ${employee.rarityClassName} ${showLabel}`}>
          {image && (
            <GatsbyImage
              className="disable-transition"
              image={image}
              alt={`${employee.fullName}`}
            />
          )}
        </div>
        {showSiege && employee.isPartOfTerrorDeck === true && (
          <span className="floating-role">
            <FontAwesomeIcon icon={faBolt} width="18" />
          </span>
        )}
        {showSiege && employee.isRearmed === true && (
          <span className="floating-role rearm">
            <FontAwesomeIcon icon={faSyringe} width="18" />
          </span>
        )}
        {showSiege && employee.hasAlternium === true && (
          <span className="floating-role core">
            <FontAwesomeIcon icon={faCube} width="18" />
          </span>
        )}
        {showSiege &&
          !employee.globalReleaseDate &&
          !employee.seaReleaseDate && (
            <span className="floating-role kr">KR</span>
          )}
        {showLabel && <span className="emp-name">{employee.shortName}</span>}
        {showLabel && employee.showNewTag && !hideTags && (
          <span className="tag new">New</span>
        )}
        {showLabel && employee.hasBeenBuffedRecently && !hideTags && (
          <span className="tag buffed">Updated</span>
        )}
      </Link>
    </CustomPopover>
  );
};
